import { ReactElement } from "react";
import style from "./style.module.scss";
import PosChoicePic1 from "../icons/posChoicePic1";
import PosChoicePic2 from "../icons/posChoicePic2";
export default function MiddleSection(): ReactElement {
    return (
        <section className=" flex flex-col gap-[100px] my-[130px]">
            <div className="flex flex-col lg:flex-row justify-between   ">
                <div
                    className={`flex flex-col w-full lg:w-1/2    ${style["mainContent"]} px-1 lg:order-1 order-2 `}
                >
                    <span
                        className={`w-[112px] h-[11px] ${style["greenSpan"]} mb-2 lg:mb-5  mt-5 lg:mt-4`}
                    />
                    <h2 className="font-bold lg:text-3xl text-xl lg:leading-[45px] max-w-lg  lg:mb-5">
                        Why Every Successful Business Needs a POS System
                    </h2>
                    <p
                        className={`${style["paragraphColor"]} lg:text-lg text-base leading-8 max-w-lg`}
                    >
                        Every successful business needs a POS (point of sale)
                        system because it is a central hub for simplifying,
                        improving, and accelerating all business operations. A
                        POS system can help manage sales, inventory, customer
                        data, and employee schedules all in one place, making
                        tracking and managing these crucial aspects of a
                        business easier.
                    </p>
                    <p
                        className={`${style["paragraphColor"]} lg:text-lg text-base leading-8 max-w-lg mt-5`}
                    >
                        With accurate sales data and inventory management,
                        businesses can make informed decisions, optimize
                        operations, and reduce costs. POS systems also provide
                        marketing and customer relationship management tools,
                        helping businesses stay connected with their customers
                        and drive sales. In short, a POS system is essential for
                        any business that wants to streamline operations,
                        improve efficiency, and maximize profitability.
                    </p>
                </div>
                <div
                    className={`w-[310px] h-[250px] lg:w-[517px]  lg:h-[323px] relative  text-center mx-auto lg:order-2 order-1`}
                >
                    <PosChoicePic1 />
                </div>
            </div>
            <div className="flex flex-col lg:flex-row justify-between ">
                <div
                    className={`w-[330px] h-[280px] lg:w-[518px]  lg:h-[444px] relative  text-center  mx-auto`}
                >
                    <PosChoicePic2 />
                </div>
                <div
                    className={`flex flex-col w-full lg:w-1/2   ${style["mainContent"]} px-1 `}
                >
                    <span
                        className={`w-[112px] h-[11px] ${style["greenSpan"]} mb-2 lg:mb-5 mt-5 lg:mt-0`}
                    />
                    <h2 className="font-bold lg:text-3xl text-xl leading-[45px] max-w-lg  lg:mb-5">
                        What Is a POS System?
                    </h2>
                    <p
                        className={`${style["paragraphColor"]} lg:text-lg text-base leading-8 max-w-lg `}
                    >
                        A point-of-sale system (POS) is a tool that helps
                        businesses process transactions and manage day-to-day
                        operations. It's where customers pay for what they buy.
                        A sound POS system is more than just a cash register; it
                        has many features, functions, and benefits that meet
                        modern standards. POS software can do everything a cash
                        register can do, and the cash drawer is often called the
                        POS cash register.
                    </p>
                </div>
            </div>
        </section>
    );
}
