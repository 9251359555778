import React from "react";

function PosChoicePic1() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 517 323"
        >
            <mask
                id="mask0_1_778"
                style={{ maskType: "alpha" }}
                width="517"
                height="323"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H517V323H0z"></path>
            </mask>
            <g clipPath="url(#clip0_1_778)" mask="url(#mask0_1_778)">
                <path
                    fill="#000"
                    d="M305.645 413.78h-27.623a.403.403 0 01-.403-.405V57.543h-14.701a.408.408 0 01-.373-.25.4.4 0 01.087-.433l28.916-28.913a.415.415 0 01.572 0l28.915 28.913a.4.4 0 01-.285.683h-14.7v355.832a.412.412 0 01-.119.286.394.394 0 01-.286.119zm-27.218-.808h26.815V57.144a.403.403 0 01.403-.405h14.127l-27.938-27.936-27.931 27.936h14.128a.405.405 0 01.405.405l-.009 355.828z"
                ></path>
                <path
                    fill="#1C1919"
                    d="M305.645 57.142H290.07l15.575 9.479v-9.48zM320.75 347.244v66.131h27.624v-66.131a23.852 23.852 0 019.595-19.118 23.741 23.741 0 0114.275-4.741h7.898c1.12 0 2.238-.043 3.345-.119a46.447 46.447 0 0029.746-13.589 46.495 46.495 0 0013.707-33.091v-14.275c0-20.215 16.446-36.657 36.66-36.657h17.257v15.099l28.916-28.911-28.916-28.914v15.104H463.6a63.918 63.918 0 00-25.026 5.055 64.068 64.068 0 00-20.429 13.77 63.983 63.983 0 00-13.77 20.427 63.888 63.888 0 00-5.059 25.027v14.275a19.21 19.21 0 01-15.757 18.869 19.134 19.134 0 01-3.42.304h-7.895a51.495 51.495 0 00-17.736 3.124 50.978 50.978 0 00-11.867 6.222 49.446 49.446 0 00-2.542 1.911 53.15 53.15 0 00-4.258 3.82 51.109 51.109 0 00-14.704 30.116 52.817 52.817 0 00-.377 6.298l-.01-.006z"
                ></path>
                <path
                    fill="#231F20"
                    d="M236.489 413.882h-27.622a.494.494 0 01-.358-.149.5.5 0 01-.147-.358V137.224a14.93 14.93 0 00-14.916-14.9 25.923 25.923 0 01-25.891-25.893V57.65h-14.592a.506.506 0 01-.357-.862l28.916-28.914a.49.49 0 01.357-.149.501.501 0 01.358.15l28.916 28.913a.506.506 0 01-.076.777.509.509 0 01-.282.085h-14.598v36.113a43.425 43.425 0 0128.054 12.672 43.711 43.711 0 016.284 7.952 43.073 43.073 0 014.578 10.105c.338 1.122.635 2.253.867 3.362a43.873 43.873 0 011.008 9.377v276.145a.506.506 0 01-.499.507zm-27.117-1.012h26.612V137.224a42.935 42.935 0 00-.983-9.16 42.07 42.07 0 00-5.318-13.147 42.678 42.678 0 00-5.012-6.576 42.412 42.412 0 00-29.022-13.595.505.505 0 01-.479-.505V57.144a.515.515 0 01.148-.358.502.502 0 01.357-.149h13.885l-27.689-27.693-27.694 27.693h13.882a.503.503 0 01.505.507v39.279a24.92 24.92 0 0024.882 24.882 15.94 15.94 0 0115.926 15.919V412.87z"
                ></path>
                <path
                    fill="#39A2A9"
                    d="M442.203 118.4l28.916 28.912-28.916 28.913V118.4z"
                ></path>
                <path
                    fill="#39A2A9"
                    d="M272.835 195.443v217.932h27.622V195.443c0-1.517.954-2.832 2.133-3.01a1.53 1.53 0 01.279-.023c1.3 0 2.41 1.388 2.41 3.033v125.541a35.407 35.407 0 006.112 19.961c5.101 7.503 13.001 12.883 22.127 14.549 1.691.309 3.403.487 5.121.533.301 0 .606.011.912.011a33.518 33.518 0 006.039-.546c15.317-2.797 27.143-16.033 28.168-32.233.048-.754.074-1.516.074-2.275V75.292c0-2.865 2.013-5.2 4.492-5.2 2.478 0 4.491 2.331 4.491 5.2v32.774c0 14.048 5.294 27.28 14.908 37.265a52.237 52.237 0 005.582 5.025c.381.297.762.585 1.152.866.538.395 1.085.779 1.641 1.153a50.047 50.047 0 003.337 2.069l.634.355a50.848 50.848 0 003.292 1.654c.247.112.494.216.741.333a56.36 56.36 0 001.82.759c.464.182.929.356 1.397.524l.395.141c.559.195 1.119.38 1.679.556.098.029.193.061.29.087.046.017.094.03.141.046.33.099.663.195.995.29a50.11 50.11 0 001.974.503c.474.112.955.216 1.436.318l.2.039c.132.028.266.052.401.078.134.026.292.056.433.08 1.538.289 3.091.499 4.659.631 1.458.123 2.931.188 4.411.188h16.381v-27.633h-16.381c-13.174 0-23.893-11.366-23.893-25.336V75.283c0-18.096-14.407-32.82-32.114-32.82-17.707 0-32.117 14.733-32.117 32.82v245.693c0 4.099-2.985 7.431-6.647 7.431-3.662 0-6.648-3.332-6.648-7.431V195.443a30.98 30.98 0 00-1.751-10.287 30.737 30.737 0 00-5.939-10.157 30.639 30.639 0 00-1.935-2.013c-.111-.104-.217-.217-.336-.312a30.194 30.194 0 00-4.786-3.601 29.408 29.408 0 00-11.64-4.067 29.617 29.617 0 00-3.658-.216 29.337 29.337 0 00-12.615 2.836 30.104 30.104 0 00-4.531 2.658 35.097 35.097 0 00-2.31 1.822 30.505 30.505 0 00-3.157 3.183 30.772 30.772 0 00-5.85 10.339 30.874 30.874 0 00-1.497 7.527 30.337 30.337 0 00-.083 2.288h.009z"
                ></path>
                <path
                    fill="#1C1919"
                    d="M168.057 57.142h10.52l-10.52 6.253v-6.253zM442.203 161v-13.688l-5.744 13.703 5.744-.015z"
                ></path>
                <path
                    fill="#39A2A9"
                    d="M480.857 225.652v-13.334l-7.296 13.334h7.296z"
                ></path>
                <path
                    fill="#000"
                    d="M31.317 177.458v27.63h28.977a43.235 43.235 0 0030.769-12.743 43.218 43.218 0 0012.746-30.766v-39.194a8.773 8.773 0 018.756-8.758 8.771 8.771 0 018.763 8.758V321.37c0 17.918 14.578 32.499 32.502 32.499 17.924 0 32.502-14.577 32.502-32.499V183.52a2.973 2.973 0 012.966-2.964 2.971 2.971 0 012.965 2.964v229.855h27.622V183.52a30.364 30.364 0 00-4.303-15.634 30.85 30.85 0 00-9.333-9.815 30.406 30.406 0 00-16.957-5.144 30.406 30.406 0 00-14.596 3.71 30.813 30.813 0 00-8.671 7.039c-.145.166-.286.335-.423.507a30.507 30.507 0 00-3.337 5.016c-.017.034-.036.065-.054.099a28.592 28.592 0 00-.919 1.903c-.028.062-.058.125-.084.188a30.348 30.348 0 00-1.538 4.485 30.585 30.585 0 00-.967 7.635V321.37a4.88 4.88 0 01-4.873 4.871 4.876 4.876 0 01-4.871-4.871V122.385c0-20.056-16.32-36.377-36.383-36.377-20.062 0-36.38 16.321-36.38 36.377v39.183a15.908 15.908 0 01-15.892 15.89H31.317z"
                ></path>
                <path
                    fill="#000"
                    d="M36.916 163.329L8 192.241l28.916 28.913v-57.825z"
                ></path>
                <path
                    fill="#1C1919"
                    d="M36.916 204.783v-11.448l5.309 11.448h-5.309z"
                ></path>
                <path
                    fill="#000"
                    d="M239.696 42.346l-9.562.312.29 8.883 9.56-.31-.288-8.883v-.002zm9.889-22.1c-.101-3.115-1.329-5.806-3.683-8.074-2.355-2.268-6.033-3.321-11.034-3.161-4.736.154-8.348 1.55-10.834 4.188-2.486 2.637-3.714 6.027-3.683 10.168l9.172.801c-.145-5.01 1.51-7.572 4.964-7.684a4.535 4.535 0 012.956.914c.856.645 1.306 1.65 1.349 3.014.048 1.487-.249 2.776-.892 3.867-.65 1.083-1.833 2.411-3.569 3.952-2.083 1.864-3.267 3.357-3.551 4.476-.285 1.12-.387 2.952-.306 5.499l.048 1.373 8.561-.277c-.064-1.935.09-3.27.461-4.006.372-.735 1.448-1.835 3.229-3.3 2.529-2.06 4.314-3.926 5.354-5.6 1.04-1.674 1.521-3.725 1.443-6.151l.015.002z"
                ></path>
                <path
                    fill="#39A2A9"
                    d="M98.297 69.892a.433.433 0 01-.414-.303l-2.801-8.82a.433.433 0 01.281-.544l9.487-3.014a.433.433 0 01.463.133.444.444 0 01.083.149l2.803 8.82a.442.442 0 01-.281.548l-9.487 3.012a.41.41 0 01-.134.02zm-2.258-8.971l2.54 7.994 8.667-2.751-2.54-7.997-8.667 2.753zm-1.014-3.034a.432.432 0 01-.2-.05.433.433 0 01-.216-.253l-.433-1.363c-.81-2.55-1.237-4.472-1.272-5.711-.035-1.3.739-3.178 2.36-5.73 1.272-1.996 2.08-3.625 2.4-4.847a6.904 6.904 0 00-.195-3.883c-.39-1.233-1.027-2.006-1.95-2.366a4.25 4.25 0 00-2.93-.067c-3.178 1.007-4.001 3.807-2.515 8.556a.432.432 0 01-.334.554l-9.408 1.79a.434.434 0 01-.498-.305c-1.235-4.223-.956-8.106.834-11.54 1.79-3.434 5.122-5.95 9.91-7.47 5.05-1.603 9.177-1.577 12.274.076 3.096 1.653 5.176 4.116 6.188 7.295.789 2.478.888 4.766.297 6.816-.579 2.01-1.87 4.452-3.837 7.254-1.331 1.91-2.083 3.273-2.232 4.051-.15.778.076 2.089.65 3.928a.436.436 0 01-.282.544l-8.496 2.7a.395.395 0 01-.115.015v.006zM93.99 32.525c.634 0 1.262.12 1.852.353 1.162.453 1.99 1.432 2.457 2.91a7.772 7.772 0 01.217 4.366c-.349 1.326-1.17 2.992-2.51 5.091-1.503 2.368-2.25 4.132-2.222 5.241.03 1.16.446 3 1.23 5.473l.302.951 7.672-2.44c-.539-1.79-.721-3.082-.557-3.938.176-.91.952-2.345 2.373-4.386 1.913-2.725 3.164-5.078 3.716-6.998.542-1.876.433-4.001-.29-6.313-.943-2.964-2.884-5.25-5.771-6.795-2.886-1.544-6.786-1.542-11.603-.01-4.56 1.447-7.727 3.817-9.406 7.043-1.627 3.127-1.922 6.663-.867 10.517l8.546-1.623c-1.43-4.957-.349-8.042 3.222-9.175a5.417 5.417 0 011.639-.273v.006z"
                ></path>
                <path
                    fill="#231F20"
                    d="M425.406 63.176a.466.466 0 01-.171-.03l-6.095-2.206a.499.499 0 01-.286-.26.511.511 0 01-.018-.388l2.052-5.666a.508.508 0 01.648-.303l6.093 2.206a.5.5 0 01.286.262.495.495 0 01.017.388l-2.049 5.665a.51.51 0 01-.263.286.489.489 0 01-.214.046zm-5.447-3.014l5.144 1.861 1.705-4.714-5.142-1.863-1.707 4.716zm7.885-4.716a.527.527 0 01-.172-.029l-5.458-1.976a.507.507 0 01-.286-.26.516.516 0 01-.017-.387l.318-.867c.611-1.688 1.147-2.834 1.641-3.508.522-.721 1.696-1.408 3.59-2.101 1.424-.514 2.468-1.025 3.101-1.517a4.259 4.259 0 001.369-1.98c.26-.728.243-1.318-.05-1.805a2.561 2.561 0 00-1.371-1.103c-.7-.251-2.817-1.022-4.407 3.289a.5.5 0 01-.498.33.514.514 0 01-.205-.053l-5.566-2.816a.506.506 0 01-.243-.635c1.053-2.724 2.776-4.658 5.118-5.75 2.343-1.092 5.12-1.068 8.251.065 3.303 1.194 5.467 2.883 6.436 5.024.968 2.14 1.083 4.284.31 6.39-.592 1.633-1.476 2.89-2.629 3.741-1.113.821-2.769 1.58-4.923 2.256-1.85.585-2.472.979-2.678 1.16-.24.218-.65.772-1.155 2.198a.508.508 0 01-.476.338v-.005zm-4.809-2.782l4.512 1.631c.418-1.057.832-1.76 1.257-2.136.481-.434 1.477-.867 3.046-1.37 2.046-.65 3.601-1.352 4.626-2.105.988-.73 1.755-1.831 2.277-3.272.679-1.876.588-3.716-.279-5.633-.854-1.883-2.817-3.393-5.85-4.492-2.871-1.04-5.389-1.083-7.48-.1-1.974.922-3.467 2.527-4.429 4.767l4.652 2.349c.932-2.234 2.6-4.411 5.495-3.365.79.274 1.459.815 1.892 1.53.455.754.502 1.65.136 2.671a5.267 5.267 0 01-1.701 2.438c-.717.556-1.852 1.118-3.374 1.668-2.084.763-2.847 1.37-3.12 1.746-.433.587-.936 1.684-1.516 3.25l-.144.423zM315.35 414.948h-58.597l-.529-9.767-3.189-49.399-4.037 59.162H192.13l3.411-59.454 1.341-22.786c.178-9.206.733-17.669 1.816-25.698 1.586-11.795 4.303-22.654 8.613-33.559a145.001 145.001 0 011.681-4.073l14.787.216 4.823.074 27.622.433 15.601.239 25.194.388.888.015 1.487 5.882.056.217 5.244 20.769c4.292 16.997 5.575 24.144 7.466 41.57l3.203 74.183-.013 1.588z"
                ></path>
                <path
                    fill="#FFA2A5"
                    d="M267.999 149.684v-16.178l-29.573-13.372c.41 11.468 1.573 22.504-1.3 29.305l-2.151.421 7.856 6.155c8.923 6.989 23.112 3.973 27.345-5.813l-2.182-.518h.005zM262.281 115.391a11.265 11.265 0 0013.1 9.056 11.264 11.264 0 009.057-13.101 11.265 11.265 0 00-13.1-9.056 11.264 11.264 0 00-9.057 13.101z"
                ></path>
                <path
                    fill="#FFA2A5"
                    d="M225.467 115.391a11.265 11.265 0 0013.1 9.056 11.264 11.264 0 009.057-13.101 11.265 11.265 0 00-13.1-9.056 11.264 11.264 0 00-9.057 13.101zM121.501 169.392a6.753 6.753 0 01-.663-.217c-2.03-.78-3.77-2.115-5.59-3.237-2.312-1.428-4.613-1.677-7.068-2.704-2.555-1.07-4.373-3.683-4.457-6.391 12.084.608 22.879 9.448 34.957 8.909 2.76-.124 6.021-.566 7.852 1.486 1.3 1.447 1.257 3.718.308 5.384-2.529 4.439-9.239 5.657-13.718 3.22-2.483-1.352-3.798-4.321-6.396-5.441-1.632-.704-3.515-.557-5.225-1.012v.003z"
                ></path>
                <path
                    fill="#FFA2A5"
                    d="M95.571 146.519c-.773-1.734-1.3-3.64-1.137-5.529.002-.391.13-.771.364-1.084.4-.448 1.137-.468 1.688-.216.55.251.962.736 1.35 1.202 3.826 4.585 13.217 14.321 11.38 20.799-.237.837-1.045 1.582-1.922 1.443a2.52 2.52 0 01-1.084-.533c-2.942-2.166-4.214-5.457-6.193-8.363-1.661-2.439-3.25-5.039-4.454-7.732l.008.013z"
                ></path>
                <path
                    fill="#FFA2A5"
                    d="M106.849 148.449c-1.378-3.297-2.305-6.76-3.683-10.055-.65-1.569-2.325-2.873-3.792-1.536-1.211 1.105-.49 5.234-.416 6.64.273 5.176 1.361 10.491 4.479 14.809 1.061 1.471 2.368 2.817 3.984 3.683 1.617.867 3.58 1.179 5.309.607 3.131-1.036.921-4.597-.386-6.103-2.253-2.6-4.142-4.788-5.486-8.034l-.009-.011zM217.881 145.828c7.989 8.974 13.809 21.642 1.447 40.758l-25.694 59.499c-.082.193-.167.383-.258.572-5.796 12.176-20.131 15.625-27.085 4.203l-6.067-13.433 26.955-59.204c7.101-25.582 19.794-34.924 30.702-32.398v.003z"
                ></path>
                <path
                    fill="#0D5356"
                    d="M208.446 211.788l-31.806-14.259 9.317-21.22c7.101-25.583 21.018-33.008 31.928-30.481 7.989 8.974 13.809 21.642 1.447 40.758l-10.881 25.202h-.005z"
                ></path>
                <path
                    fill="#FFA2A5"
                    d="M191.175 228.78l-41.254-65.804-17.681 11.888 30.42 67.007 28.515-13.091zM126.528 152.319c-1.378-3.298-2.307-6.76-3.683-10.056-.65-1.568-2.327-2.872-3.794-1.536-1.212 1.105-.49 5.235-.416 6.641.273 5.176 1.36 10.491 4.478 14.809 1.064 1.471 2.369 2.816 3.987 3.683 1.619.867 3.578 1.179 5.309.607 3.129-1.036.919-4.598-.388-6.104-2.251-2.6-4.141-4.788-5.484-8.033l-.009-.011z"
                ></path>
                <path
                    fill="#FFA2A5"
                    d="M116.641 150.598c-1.378-3.297-2.307-6.759-3.683-10.055-.65-1.568-2.327-2.873-3.794-1.536-1.212 1.105-.49 5.232-.416 6.641.273 5.176 1.36 10.49 4.478 14.808 1.064 1.472 2.369 2.817 3.987 3.684 1.619.866 3.578 1.178 5.309.606 3.129-1.035.919-4.597-.388-6.103-2.251-2.6-4.141-4.788-5.484-8.034l-.009-.011z"
                ></path>
                <path
                    fill="#FFA2A5"
                    d="M134.329 154.204c-7.907-3.034-16.442-4.672-24.895-5.395-2.78-.239-6.099-.171-7.269 1.633-1.357 2.095 1.239 4.767 3.829 6.481 8.998 5.932 20.138 9.026 31 11.437 3.564.791 10.554 3.363 13.566 1.467 2.561-1.614 1.318-4.888-.639-6.851-3.729-3.742-10.277-6.738-15.601-8.772h.009z"
                ></path>
                <path
                    fill="#0D5356"
                    d="M303.094 199.366l-12.203-54.004-12.375 1.048-9.293.999-28.468-1.339-17.072-.479-11.412-.162-10.412 53.082c-.091 2.914 1.751 21.033 1.636 24.003-.971 25.402-4.884 49.182 4.033 50.989l92.626 3.155c7.057-2.355 2.806-23.848 2.94-39.779v-37.513z"
                ></path>
                <path
                    fill="#231F20"
                    d="M280.935 100.127a10.437 10.437 0 00-1.448-3.774c-.513-.81-1.928-1.77-2.24-2.413-1.279-2.629-.304-5.462-2.709-7.913-2.983-3.033-9.967-4.602-13.952-5.936a97.483 97.483 0 00-17.859-4.169c-2.66-.36-5.599-.546-7.8 1.001-2.202 1.547-2.654 5.51-.23 6.66-2.463-1.373-5.867-1.393-7.846.615-1.978 2.009-1.445 6.102 1.242 6.949-.743-1.71-3.64-1.248-4.412.45-.771 1.7.039 3.725 1.227 5.166 1.187 1.44 2.741 2.56 3.817 4.084 1.155 1.635 1.684 3.62 2.189 5.557 2.6 9.943 2.401 20.347 10.892 27.839 1.246 1.1 2.902 1.822 4.551 1.655 1.012-.1 1.95-.518 2.944-.763a8.73 8.73 0 014.655.13c1.289.399 2.474 1.084 3.77 1.474 5.12 1.501 10.944-2.336 13.681-6.418 3.077-4.593 4.199-10.146 6.501-15.105 2.192-4.708 3.516-8.61 3.15-13.893-.027-.401-.076-.8-.145-1.196h.022zM316.043 229.621a.302.302 0 01-.268-.442l13.217-25.455a.304.304 0 01.559.048.305.305 0 01-.02.231l-13.217 25.455a.303.303 0 01-.271.163z"
                ></path>
                <path
                    fill="#FFA2A5"
                    d="M286.599 145.828c-7.989 8.974-13.809 21.642-1.448 40.758l25.694 59.499c.082.193.169.383.258.572 5.796 12.176 20.132 15.625 27.085 4.203l6.067-13.433-26.955-59.204c-7.101-25.582-19.794-34.924-30.701-32.398v.003z"
                ></path>
                <path
                    fill="#0D5356"
                    d="M291.675 214.869l37.31-18.044-10.459-20.516c-7.1-25.583-21.018-33.008-31.927-30.481-7.989 8.974-16.611 21.947-4.25 41.061l9.318 27.98h.008z"
                ></path>
                <path
                    fill="#231F20"
                    d="M291.675 215.375a.488.488 0 01-.192-.039.485.485 0 01-.177-.122.483.483 0 01-.109-.185l-9.318-27.98a.5.5 0 01.028-.386.524.524 0 01.121-.156.523.523 0 01.565-.074.515.515 0 01.257.298l9.142 27.425 5.696-2.847a.508.508 0 01.678.216.503.503 0 01-.216.679l-6.219 3.109a.495.495 0 01-.256.062z"
                ></path>
                <path
                    fill="#FFA2A5"
                    d="M313.304 228.78l38.589-65.273 21.016 9.882-31.09 68.482-28.515-13.091z"
                ></path>
                <path
                    fill="#FFA2A5"
                    d="M381.184 170.117c.231-.06.459-.133.682-.216 2.091-.804 3.885-2.18 5.762-3.337 2.383-1.471 4.754-1.733 7.285-2.786 2.632-1.101 4.504-3.787 4.591-6.587-12.453.629-23.579 9.75-36.025 9.18-2.847-.125-6.208-.58-8.093 1.534-1.333 1.491-1.3 3.831-.319 5.549 2.6 4.576 9.534 5.83 14.139 3.319 2.559-1.393 3.915-4.452 6.593-5.609 1.682-.726 3.621-.574 5.385-1.042v-.005z"
                ></path>
                <path
                    fill="#FFA2A5"
                    d="M407.907 146.545c.795-1.799 1.341-3.753 1.172-5.698a1.881 1.881 0 00-.377-1.114c-.412-.462-1.172-.483-1.733-.217-.562.267-.99.759-1.389 1.24-3.944 4.725-13.616 14.758-11.731 21.436.245.867 1.083 1.631 1.98 1.488.408-.092.788-.28 1.11-.548 3.033-2.227 4.333-5.633 6.383-8.621 1.712-2.513 3.359-5.2 4.591-7.968l-.006.002z"
                ></path>
                <path
                    fill="#FFA2A5"
                    d="M396.282 148.54c1.419-3.399 2.383-6.968 3.805-10.365.678-1.616 2.396-2.96 3.9-1.582 1.248 1.138.505 5.393.433 6.845-.281 5.332-1.401 10.809-4.615 15.259-1.096 1.517-2.442 2.903-4.117 3.785-1.675.882-3.683 1.216-5.471.626-3.226-1.066-.949-4.738.399-6.283 2.32-2.676 4.268-4.935 5.653-8.278l.013-.007zM376.005 152.522c1.419-3.397 2.384-6.965 3.805-10.363.678-1.616 2.396-2.959 3.9-1.581 1.248 1.137.505 5.393.434 6.844-.28 5.332-1.402 10.809-4.616 15.26-1.094 1.516-2.439 2.903-4.106 3.785-1.666.881-3.683 1.215-5.473.626-3.224-1.068-.947-4.739.399-6.283 2.322-2.676 4.27-4.936 5.655-8.281l.002-.007z"
                ></path>
                <path
                    fill="#FFA2A5"
                    d="M386.191 150.75c1.422-3.399 2.384-6.968 3.805-10.365.678-1.616 2.397-2.96 3.9-1.582 1.249 1.138.505 5.395.434 6.845-.282 5.334-1.402 10.811-4.616 15.261-1.096 1.517-2.439 2.901-4.116 3.783-1.678.882-3.684 1.216-5.472.626-3.224-1.066-.946-4.738.399-6.283 2.321-2.676 4.269-4.935 5.653-8.278l.013-.007z"
                ></path>
                <path
                    fill="#FFA2A5"
                    d="M367.962 154.466c8.147-3.12 16.944-4.814 25.657-5.562 2.865-.245 6.284-.175 7.491 1.686 1.397 2.166-1.277 4.916-3.946 6.677-9.272 6.112-20.754 9.304-31.947 11.789-3.684.814-10.878 3.466-13.983 1.516-2.641-1.664-1.358-5.035.65-7.058 3.842-3.857 10.592-6.934 16.069-9.042l.009-.006z"
                ></path>
                <path
                    fill="#231F20"
                    d="M341.596 224.874a.51.51 0 01-.433-.254l-12.609-21.934a.51.51 0 01.187-.691.505.505 0 01.691.186l12.608 21.935a.51.51 0 01-.186.691.505.505 0 01-.258.067zm-174.284-.171a.499.499 0 01-.469-.319.519.519 0 01.005-.388l9.328-21.763a.506.506 0 01.93.399l-9.328 21.763a.51.51 0 01-.466.308zm35.733-14.831a.506.506 0 01-.503-.459l-1.491-15.816a.519.519 0 01.114-.37.506.506 0 01.894.274l1.49 15.817a.495.495 0 01-.267.496.503.503 0 01-.19.056l-.047.002z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_1_778">
                    <path
                        fill="#fff"
                        d="M0 0H501.773V405.948H0z"
                        transform="translate(8 9)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default PosChoicePic1;
